import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/styles/styles.css"
import ITpipe from "../../static/assets/img/IT-PIpe-Assests-Workspace-768x480.png"
import ITwebImg from "../../static/assets/img/itpipes-all-devices-300x199.png"
import James from "../../static/assets/img/james-knowles-300x225.jpg"
import QuestionMark from "../../static/assets/img/sync-with-question-marks-300x273.jpg"
import Clouds from "../../static/assets/img/itpipes-cloud-cloud-1-300x200.png"
import Desktop from "../../static/assets/img/itpipes-desktop-on-monitor-300x200.png"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

const Software = () => (
  <Layout>
    <SEO
      title="Software"
      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    />
    <div className="page-headline">
      <div className="container">
        <div className="section-heading text-center">
          <h1 className="font-weight-bold text-uppercase flair">
            <strong>Software</strong>
          </h1>
        </div>
      </div>
    </div>
    <div className="page-content">
      <div className="container">
        <div className="row flex-column-reverse flex-md-row justify-content-between align-items-center">
          <div className="col-md-6 vertical-align">
            <img className="img-fluid" src={ITpipe} alt="peaceful" />
          </div>
          <div className="col-md-6">
            <h2 className="primary-clr mb-2">ITPIPES SOFTWARE</h2>
            <p>
              The ITpipes platform provides a comprehensive software suite to
              manage your pipeline inspection management needs. Dependent on
              your business processes, various products may meet your demands.
              ITpipes consists of 6 products, outlined below. Each product has
              different features, functions and focus. By selecting the product
              tab on the left, you can read more and get a very brief overview
              of each product. Each product does have its own page you can
              select to review for more detail! Use the tabs below to learn more
              about ITPipes Software features!
            </p>
            <Link to="/contact-us" className="btn-global hvr-bounce-to-right">
              INTERESTED? CONTACT US TODAY!
            </Link>
          </div>
        </div>
        <div className="tabs">
          <Tabs>
            <TabList>
              <Tab>ITPipes Web</Tab>
              <Tab>ITPipes Mobility</Tab>
              <Tab> ITpipes Mobile/CCTV</Tab>
              <Tab>ITpipes Sync</Tab>
              <Tab>ITPipes Cloud</Tab>
              <Tab>ITPipes Desktop</Tab>
            </TabList>

            <TabPanel className="mt-5">
              <p>
                <a
                  href="https://itpipes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ITpipes Web
                </a>{" "}
                creates actionable intelligence from your pipeline inspections.
                Web is browser based for simplified deployment and has powerful
                features for inspection data management, review, planning,
                scheduling, and more. Web is the ultimate tool for pipeline
                inspection management and reporting in the office, providing
                quick inspection review access in the field or as needed.
              </p>

              <div className="row">
                <div className="col-md-8">
                  <p>
                    <a
                      href="https://itpipes.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ITpipes Web
                    </a>{" "}
                    has automated integration to GIS mapping, so your reports
                    instantly show up as color-coded assets. With unlimited
                    drill-down filtering and querying on any data collected,
                    ITpipes dynamically updates SmartTabs, as new inspections
                    sync, to give you instant reports. And the same reports with
                    drill-down color coding display on the map, updating
                    real-time as new inspections sync!
                  </p>
                  <p>
                    With options for internal hosting or using ITpipes Cloud,{" "}
                    <a
                      href="https://itpipes.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      ITpipes Web
                    </a>{" "}
                    provides access to inspections anytime, anywhere, on any
                    device.
                  </p>
                </div>
                <div className="col-md-4">
                  <img src={ITwebImg} alt="IT web" className="my-3" />
                </div>
              </div>
              <p>
                All ITpipes products have the following features and benefits:
              </p>
              <ul className="checks-ul">
                <li className="list-checks">
                  We realize your inspection data belongs to you and all data is
                  stored in open architecture formats.
                </li>
                <li className="list-checks">
                  All our office products allow export to various formats and
                  easy sharing of information.
                </li>
                <li className="list-checks">
                  An asset based application and database, yet ITpipes has tools
                  that allow you to work off all available asset or inspection
                  detailed information, specific to your business needs.
                </li>
                <li className="list-checks">
                  ITpipes is fully configurable to work with your specific
                  compliance standard or a custom setup.
                </li>
                <li className="list-checks">
                  The entire ITpipes platform is backed by unparalleled support
                  and in-house development staff.
                </li>
              </ul>
            </TabPanel>
            <TabPanel>
              <p>
                <a
                  href="https://itpipes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ITpipes Mobility
                </a>{" "}
                simplifies in the field inspections for underground assets by
                giving you portable inspections on an android tablet or phone
                device! For streamlining your quick inspections, Mobility is the
                ultimate tool to save you time, tracking, management, shuffling
                paper and more. Increase your efficiency, accuracy and
                productivity with Mobility today!
              </p>
              <p>
                Mobility works with various manufacturers equipment, such as
                digital, manhole inspection, or other down hole type cameras.
              </p>
              <p>
                All ITpipes products have the following features and benefits:
              </p>
              <ul className="checks-ul">
                <li className="list-checks">
                  We realize your inspection data belongs to you and all data is
                  stored in open architecture formats.
                </li>
                <li className="list-checks">
                  All our office products allow export to various formats and
                  easy sharing of information.
                </li>
                <li className="list-checks">
                  An asset based application and database, yet ITpipes has tools
                  that allow you to work off all available asset or inspection
                  detailed information, specific to your business needs.
                </li>
                <li className="list-checks">
                  ITpipes is fully configurable to work with your specific
                  compliance standard or a custom setup.
                </li>
                <li className="list-checks">
                  The entire ITpipes platform is backed by unparalleled support
                  and in-house development staff.
                </li>
              </ul>
            </TabPanel>
            <TabPanel>
              <p>
                <a
                  href="https://itpipes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ITpipes Mobile
                </a>{" "}
                expedites pipeline inspection surveys and boosts inspection
                footage and performance. Working with various OEM pipeline
                inspection camera systems, ITpipes improves your data collection
                quality and speed, while streamlining your administration
                efforts.{" "}
                <a
                  href="https://itpipes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ITpipes Mobile
                </a>{" "}
                is{" "}
                <a
                  href="https://www.nassco.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NASSCO
                </a>{" "}
                certified,{" "}
                <a
                  href="http://spicap.info/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SPICAP
                </a>{" "}
                certified and CH2MHill{" "}
                <a
                  href="http://ascelibrary.org/doi/full/10.1061/%28ASCE%29PS.1949-1204.0000055?src=recsys&"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SCREAM
                </a>{" "}
                compliant.
              </p>
              <p>
                Our mobile solutions use software based overlay encoding with
                off-the-shelf hardware to communicate with your cctv cameras and
                other hardware. Video recording inside ITpipes uses
                off-the-shelf hardware via internal or external devices and we
                do communicate with several proprietary hardware MPEG encoding
                cards.
              </p>
              <div className="row">
                <div className="col-md-8">
                  <p>
                    All ITpipes products have the following features and
                    benefits:
                  </p>
                  <ul className="checks-ul">
                    <li className="list-checks">
                      We realize your inspection data belongs to you and all
                      data is stored in open architecture formats.
                    </li>
                    <li className="list-checks">
                      All our office products allow export to various formats
                      and easy sharing of information.
                    </li>
                    <li className="list-checks">
                      An asset based application and database, yet ITpipes has
                      tools that allow you to work off all available asset or
                      inspection detailed information, specific to your business
                      needs.
                    </li>
                    <li className="list-checks">
                      ITpipes is fully configurable to work with your specific
                      compliance standard or a custom setup.
                    </li>
                    <li className="list-checks">
                      The entire ITpipes platform is backed by unparalleled
                      support and in-house development staff.
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <img src={James} alt="Question Mark" className="my-3" />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <p>
                <a
                  href="https://itpipes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ITpipes Sync
                </a>{" "}
                automatically merges new inspections from the field into the
                office for streamlined inspection access and reporting! Sync is
                deployed for agencies with multiple departments, by contractors
                with multiple projects and clients, and clients looking to
                integrate inspections into other systems. With automated
                management of your pipe inspections, sync merges, copies,
                replicates, archives, and fully organizes your pipeline data.
              </p>

              <div className="row">
                <div className="col-md-8">
                  <p>
                    All ITpipes products have the following features and
                    benefits:
                  </p>
                  <ul className="checks-ul">
                    <li className="list-checks">
                      We realize your inspection data belongs to you and all
                      data is stored in open architecture formats.
                    </li>
                    <li className="list-checks">
                      All our office products allow export to various formats
                      and easy sharing of information.
                    </li>
                    <li className="list-checks">
                      An asset based application and database, yet ITpipes has
                      tools that allow you to work off all available asset or
                      inspection detailed information, specific to your business
                      needs.
                    </li>
                    <li className="list-checks">
                      ITpipes is fully configurable to work with your specific
                      compliance standard or a custom setup.
                    </li>
                    <li className="list-checks">
                      The entire ITpipes platform is backed by unparalleled
                      support and in-house development staff.
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <img
                    src={QuestionMark}
                    alt="Question Mark"
                    className="my-3"
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="text-center">
                <img src={Clouds} alt="ITpipes Clouds" className="my-3" />
              </div>
              <p>
                <a
                  href="https://itpipes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ITpipes Cloud
                </a>{" "}
                securely stores your comprehensive pipe inspection information
                on the web for unparalleled access. Deployed for agencies
                looking to simplify their data management of pipe inspections,
                minimize their capital investment in hardware, and minimize the
                demand on IT resources, this turn-key solution administers
                unlimited access to your inspections. ITpipes cloud, powered
                with web or desktop, provides inspection intelligence anytime,
                anywhere.
              </p>
            </TabPanel>
            <TabPanel>
              <div className="text-center">
                <img src={Desktop} alt="ITpipes Clouds" className="my-3" />
              </div>
              <p>
                <a
                  href="https://itpipes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ITpipes Desktop
                </a>{" "}
                provides a comprehensive toolkit for successful management of
                your underground inspections. Sharing inspections collected with{" "}
                <a
                  href="https://itpipes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ITpipes Mobile
                </a>
                /cctv or Mobility, desktop installs use your system resources
                for unrestricted access to your inspections. Use our powerful
                tools for data organization and management at your PC, accessing
                data from local servers or ITpipes cloud.{" "}
                <a
                  href="https://itpipes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ITpipes Desktop
                </a>{" "}
                is{" "}
                <a
                  href="https://www.nassco.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NASSCO
                </a>{" "}
                certified,{" "}
                <a
                  href="http://spicap.info/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SPICAP
                </a>
                certified and CH2MHill{" "}
                <a
                  href="https://ascelibrary.org/doi/full/10.1061/%28ASCE%29PS.1949-1204.0000055?src=recsys&"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SCREAM
                </a>{" "}
                compliant.
              </p>
              <p>
                All ITpipes products have the following features and benefits:
              </p>
              <ul className="checks-ul">
                <li className="list-checks">
                  We realize your inspection data belongs to you and all data is
                  stored in open architecture formats.
                </li>
                <li className="list-checks">
                  All our office products allow export to various formats and
                  easy sharing of information.
                </li>
                <li className="list-checks">
                  An asset based application and database, yet ITpipes has tools
                  that allow you to work off all available asset or inspection
                  detailed information, specific to your business needs.
                </li>
                <li className="list-checks">
                  ITpipes is fully configurable to work with your specific
                  compliance standard or a custom setup.
                </li>
                <li className="list-checks">
                  The entire ITpipes platform is backed by unparalleled support
                  and in-house development staff.
                </li>
              </ul>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  </Layout>
)

export default Software
